import React from "react";
import { Link } from "react-router-dom";
import ImageHandlerService from "../../services/image-handler";
import thumbnailDefault from "../../assets/img/producer-thumbnail.jpg";
import placeIcon from "../../assets/img/place.png";

import "./index.css";
import { isInZigApp } from "../../services/utils";
import MoengageService from "../../services/moengage";
import { useTranslation } from "react-i18next";

export default function Index({ event, location, user }) {
  const producerImage = ImageHandlerService.getImageFromUrl(
    event.producer.thumbnail,
    {
      format: "webp",
      resize: { height: 120, fit: "contain" },
    },
    thumbnailDefault
  );
  const { t } = useTranslation();

  const locationButtonHandleClick = async () => {
    if (user) {
      await MoengageService.createEvent({
        type: "event",
        customer_id: user.id,
        actions: [
          {
            action: "View map",
            attributes: {
              event_id: event.id,
              organization: event.producer.name,
              location_address: location?.name || location?.formatted_address,
            },
          },
        ],
        platform: "web",
      });
    }
  };

  return (
    <div className="background-produtores">
      <div className="container">
        <div className="producer-info row d-flex justify-content-between flex-column flex-sm-row">
          <div className="col-md-5 col-sm-12">
            <div className="productor">
              <div
                className="productor-img"
                style={{ backgroundImage: `url(${producerImage})` }}
              ></div>

              <div className="details d-flex flex-column justify-content-center">
                <span className="produced-by">
                  {t("Productors.producedBy")}
                </span>
                <h4 className="producer-name">{event.producer.name}</h4>
                {!isInZigApp() && (
                  <a
                    href={`/${
                      event.producer.type === "organization"
                        ? "organizacoes"
                        : "produtores"
                    }/${event.producer.slug}`}
                    className="product-links"
                  >
                    {t("Productors.moreEvents")}
                  </a>
                )}{" "}
              </div>
            </div>
          </div>
          <div className="producer-location-divider d-none d-md-flex"></div>
          <div className="col-md-5 col-sm-12">
            <div className="location">
              <div
                className="productor-img "
                style={{
                  backgroundImage: `url(${placeIcon})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
              <div className="details d-flex flex-column justify-content-center">
                <div className="details-location-title d-flex flex-column">
                  <span className="location-event">
                    {t("Productors.eventLocation")}
                  </span>

                  <h4 className="location-name">{location?.name}</h4>
                </div>

                <div className="details-location-address">
                  <div className="location-info">
                    <span>
                      {location.is_online === 1
                        ? "Online"
                        : location.formatted_address}
                    </span>
                  </div>
                  {!isInZigApp() && location?.zip_code && (
                    <Link
                      to={`/localidades/${location?.zip_code}`}
                      className="product-links"
                    >
                      {t("Productors.moreEventsInThisLocation")}
                    </Link>
                  )}
                </div>
                <div className="button-wrapper">
                  <a
                    className="see-location-button"
                    rel="noopener noreferrer"
                    onClick={locationButtonHandleClick}
                    href={`https://www.google.com.br/maps/place/${location?.formatted_address},+${location?.zip_code}`}
                    target="_blank"
                  >
                    <span>{t("Productors.seeLocation")}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

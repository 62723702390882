import generic from '../assets/svg/error/generic.svg';
import error from '../assets/svg/error/error.svg';
import invalid from '../assets/svg/error/invalid.svg';
import timeOut from '../assets/svg/error/timeOut.svg';
import checkout from '../assets/svg/error/checkoutNoExist.svg';
import seats from '../assets/svg/error/seats.svg';


const errorIcons = {
    generic,
    system: generic,
    error,
    invalid,
    duplicate: invalid,
    value: invalid, 
    refused: invalid,
    card: invalid,
    timeOut,
    checkout,
    seats,
    notAvailable: error,
    default: generic 
  };

  export function getErrorIcon(errorType) {
    return errorIcons[errorType] || errorIcons.default;
  }
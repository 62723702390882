import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import AuthenticationFlow from "../../components/Auth";
import { useAuth } from "../../hooks/useAuth";

export default function Auth() {
  const { isLogged } = useAuth();
  const { search } = useLocation();
  
  const urlParams = useMemo(() =>  new URLSearchParams(search), [search])

  useEffect(() => {
    if (isLogged) {
      window.location.href = urlParams.get("next") || "/";
    }
  }, [isLogged, urlParams]);

  return (
    <div style={{ backgroundColor: "#0050c3", height: "calc(100vh - 305px)" }}>
      <AuthenticationFlow show onClose={() => window.location.reload()} />
    </div>
  );
}

import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import AttendeeQuestionView from "./AttendeeQuestionView";
import { ticketNomenclatureInSingular } from "../../services/constants";

export const TicketDetailView = ({
  formQuestions,
  clearErrors,
  onSetValue,
  translate,
  attendee,
  register,
  errors,
  index,
  event,
  watch,
  user,
  getValues,
}) => {
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const handleEditAttendee = (e, item) => {
    e.preventDefault();

    item.automatic_fill = !item.automatic_fill;

    if (!!item.passport_name) {
      item.email = "";
      item.last_name = "";
      item.first_name = "";
      item.use_my_data = false;
    }

    clearErrors("attendees");
    onSetValue("attendees", [...watch("attendees")]);
  };

  const handleUseMyData = (event, item) => {
    if (event.target.checked) {
      item.email = user.email;
      item.last_name = user.last_name;
      item.first_name = user.first_name;
      item.use_my_data = true;
      setIsEmailFocused(false)
    } else {
      item.email = "";
      item.last_name = "";
      item.first_name = "";
      item.use_my_data = false;
    }

    clearErrors("attendees");
    onSetValue("attendees", [...watch("attendees")]);
  };

  const handleAnswer = (event, eventForm, attendeeIndex, questionIndex) => {
    const field = `attendees[${attendeeIndex}].answers[${questionIndex}].answer`;

    let value = event.target.value;
    let data = watch(field);

    if (eventForm.field_type.type === "checkbox") {
      data = JSON.parse(data || "[]");
      const index = data.findIndex((item) => item === value);

      if (index !== -1) {
        data.splice(index, 1);
      } else {
        data.push(value);
      }

      data = data.length ? JSON.stringify(data) : "";
    } else {
      data = value;
    }

    onSetValue(field, data);
  };

  return (
    <Row className="ticket-detail">
      <Col xs={12}>
        <div className="card-title">
          {attendee.passport_name ? (
            <>
              {attendee.passport_name} ({attendee.passport_index}):{" "}
              {ticketNomenclatureInSingular(event.ticket_nomenclature)}{" "}
              {index + 1} - {attendee.ticket}
              {attendee.seat_name ? ` - ${attendee.seat_name}` : ""}
            </>
          ) : (
            <>
              {" "}
              {ticketNomenclatureInSingular(event.ticket_nomenclature)}{" "}
              {index + 1} - {attendee.ticket}
              {attendee.seat_name ? ` - ${attendee.seat_name}` : ""}
            </>
          )}
        </div>
      </Col>
      <Col xs={12} className={attendee.automatic_fill ? "d-none" : "d-block"}>
        <Form.Group>
          <Form.Check
            id={"autofill-" + index}
            label={translate("Checkout.fillWithMyData")}
            inline={true}
            className="input-autofill"
            checked={attendee.use_my_data}
            onChange={(e) => handleUseMyData(e, attendee)}
          />
        </Form.Group>
      </Col>
      <Col xs={12} className={attendee.automatic_fill ? "d-block" : "d-none"}>
        <Form.Group>
          <div className="prefillContainer">
            <div className="prefillInfoContainer">
              <span className="prefillAttendeeName">
                {`${attendee.first_name} ${attendee.last_name}`}
              </span>
              <span className="prefillAttendeeEmail">{attendee.email}</span>
            </div>
            <button
              className="prefillEditButton"
              onClick={(e) => handleEditAttendee(e, attendee)}
            >
              {translate("Checkout.edit")}
            </button>
          </div>
        </Form.Group>
      </Col>
      <Col xs={6} className={attendee.automatic_fill ? "d-none" : "d-block"}>
        <Form.Group>
          <Form.Label htmlFor={"first_name-" + index}>
            <span className="text-danger">*</span>
            {translate("CommonExpressions.name")}
          </Form.Label>
          <Form.Control
            type="text"
            id={"first_name-" + index}
            className={errors.attendees?.[index]?.first_name && "is-invalid"}
            {...register(`attendees.[${index}].first_name`)}
          />
          {errors.attendees?.[index]?.first_name && (
            <Form.Text className="error-message">
              {errors.attendees?.[index]?.first_name?.message}
            </Form.Text>
          )}
        </Form.Group>
      </Col>
      <Col xs={6} className={attendee.automatic_fill ? "d-none" : "d-block"}>
        <Form.Group>
          <Form.Label htmlFor={"last_name-" + index}>
            <span className="text-danger">*</span>
            {translate("CommonExpressions.lastname")}
          </Form.Label>
          <Form.Control
            id={"last_name-" + index}
            type="text"
            className={errors.attendees?.[index]?.last_name && "is-invalid"}
            {...register(`attendees[${index}].last_name`)}
          />
          {errors.attendees?.[index]?.last_name && (
            <Form.Text className="error-message">
              {errors.attendees?.[index]?.last_name?.message}
            </Form.Text>
          )}
        </Form.Group>
      </Col>
      <Col className={`mt-2 ${attendee.automatic_fill ? "d-none" : "d-block"}`}>
        <Form.Group>
          <Form.Label htmlFor={"email-" + index}>
            <span className="text-danger">*</span>
            {translate("Checkout.email")}
          </Form.Label>
          <Form.Control
            id={"email-" + index}
            type="email"
            className={errors.attendees?.[index]?.email && "is-invalid"}
            {...register(`attendees[${index}].email`)}
            onFocus={() => !attendee.use_my_data && setIsEmailFocused(true)}
            onBlur={() => {
              const emailValue = getValues(`attendees[${index}].email`);
              onSetValue(`attendees[${index}].email`, emailValue, {
                shouldValidate: true,
                shouldTouch: true,
              });
            }}
          />
          {errors.attendees?.[index]?.email && (
            <Form.Text className="error-message">
              {errors.attendees?.[index]?.email?.message}
            </Form.Text>
          )}
        </Form.Group>
        {isEmailFocused && (
          <div className="informative-box">
            <span>{translate("Checkout.ticketsWillBeSentToEmail")}</span>
          </div>
        )}
      </Col>

      {formQuestions.map((field, idx) => (
        <AttendeeQuestionView
          key={index}
          id={`q-${index}-${idx}`}
          name={`attendees[${index}].answers[${idx}].answer`}
          field={field}
          value={watch(`attendees[${index}].answers[${idx}].answer`)}
          errors={errors.attendees?.[index]?.answers?.[idx]?.answer}
          register={register}
          onChange={(ev) => handleAnswer(ev, field, index, idx)}
        />
      ))}
    </Row>
  );
};

import * as Sentry from "@sentry/react";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { RecoilRoot } from "recoil";
import packageInfo from "../package.json";
import App from "./App";
import "./i18n";

const dsn = process.env.REACT_APP_SENTRY_DSN;
const environment = process.env.REACT_APP_SENTRY_ENVIRONMENT;

Sentry.init({
  dsn,
  release: packageInfo.version,
  environment,
  integrations: [new Sentry.Replay()],
  ignoreErrors: [
    "Error: Request failed with status code 404",
    "Error: Cannot find module './en'",
  ],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
});

const root = createRoot(document.getElementById("root"));

root.render(
  <RecoilRoot>
    <App />
  </RecoilRoot>,
);

import React, { Fragment, useEffect, useState } from "react";
import "./styles.css";

import Clock from "../../../assets/svg/Clock.svg";
import PicPay from "../../../assets/svg/picpay-1 1.svg";
import ValidTicket from "../../../assets/svg/Valid Ticket.svg";
import InvalidTicket from "../../../assets/svg/Invalid Ticket.svg";
import Stopwatch from "../../../assets/svg/stopwatch.svg";
import { Card, Image } from "react-bootstrap";
import DownloadApp from "../../../components/DownloadApp";
import { useAuth } from "../../../hooks/useAuth";
import { useParams, useHistory } from "react-router-dom";
import { getSessionStorageItems } from "../../../services/utils";
import QRCodeSection from "../../../components/QRCodeSection";
import { Trans, useTranslation } from "react-i18next";
import PixSection from "./components/PixSection";
import PicpaySection from "./components/PicpaySection";
import ConcludedSection from "./components/ConcludedSection";
import ErrorSection from "./components/ErrorSection";
import InstructionsSection from "./components/InstructionsSection";
import TipSection from "./components/TipSection";
import ProcessingSection from "./components/ProcessingSection";
import BoletoSection from "./components/BoletoSection";
import PixLogo from "../../../assets/svg/pix_logo.svg";
import OrderValueSection from "./components/OrderValueSection";
import NewApi from "../../../services/new-api";
import MaskHelper from "../../../Helpers/mask";
import DateHelper from "../../../Helpers/date";
import Swal from "sweetalert2";
import NewLoading from "../../../components/NewLoading";
import PixAccordion from "./PixAccordion";

const sessionItems = [
  "order_token",
  "order_value",
  "pix-code",
  "picpay-url",
  "picpay-code",
  "error-message",
  "boleto",
  "type",
  "tickets",
  "payment_date",
];

const isWhitelabel = !!Number(process.env.REACT_APP_IS_WHITELABEL);

export default function ResultCheckoutPage() {
  const {
    token,
    orderValue,
    pix,
    picpayUrl,
    picpayCode,
    errorMessage,
    boleto,
    type,
    tickets,
    paymentDate,
  } = getSessionStorageItems(sessionItems);
  const { user } = useAuth();
  const { status } = useParams();
  const { t } = useTranslation();
  const [isCopy, setIsCopy] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const api = new NewApi();

  const handleRedirect = (action) => {
    const urls = {
      picpay: picpayUrl,
      myTickets: "/meus-ingressos",
      myOrders: "/meus-pedidos",
      tryAgain: `/eventos/${localStorage.getItem("slug")}`,
      boleto,
    };
    window.location.href = urls[action];
  };

  const handleOpenModal = () => {
    setIsOpen(!isOpen);
  };

  const handleCopyPix = () => {
    navigator.clipboard.writeText(pix);
    setIsCopy(true);

    setTimeout(() => {
      setIsCopy(false);
    }, 2000);
  };

  const getPendingIcon = () => {
    if (type === "pix") {
      return PixLogo;
    }

    if (type === "picpay") {
      return PicPay;
    }

    return Clock;
  };

  const checkoutStatus = {
    pending: {
      icon: getPendingIcon(),
      title: `CheckoutStatus.pending.title${type}`,
    },
    processing: {
      icon: Clock,
      title: "CheckoutStatus.processing.title",
    },
    concluded: {
      icon: ValidTicket,
      title: "CheckoutStatus.concluded.title",
    },
    error: {
      icon: InvalidTicket,
      title: "CheckoutStatus.error.title",
    },
    timeout: {
      icon: Stopwatch,
      title: "CheckoutStatus.timeout.title",
    },
  };

  const getOrderStatus = async () => {
    const { status, expire_date } = await api
      .get(`orders/${token}?onlyOrder=true`)
      .then(({ data }) => data);
    return {status, expire_date};
  };

  const clearSessionAndCloseTo = (route) => {
    sessionStorage.removeItem("type");
    sessionStorage.removeItem("pix-code");
    sessionStorage.removeItem("order_value");
    sessionStorage.removeItem("payment_date");
    setLoading(false)
    history.push(`/status/checkout/${route}`);
  };

  const handleCheckPayment = async () => {
    setLoading(true)
    const {status, expire_date} = await getOrderStatus();

    if (["finalizado", "expirado"].includes(status) || new Date(expire_date) < new Date()) {
      if (status === "finalizado") {
        clearSessionAndCloseTo("concluded");
      } else {
        clearSessionAndCloseTo("timeout");
      }
    } else {
      setLoading(false)
      Swal.fire({
        imageUrl:
          "http://cdn.mcauto-images-production.sendgrid.net/c751db91e17f1b66/ccb11ef7-c7ef-4ca5-86d2-633d15bef350/65x65.png",
        imageHeight: 65,
        imageWidth: 65,
        padding: 24,
        imageAlt: "Clock icon",
        confirmButtonText: "Entendi",
        customClass: {
          confirmButton: "button-contained primary-contained",
          popup: "error-payment-container",
          title: "error-payment-title",
          htmlContainer: "error-payment-text",
          actions: "error-payment-actions",
        },
        buttonsStyling: false,
        confirmButtonColor: "#1C70E9",
        title: t("CheckoutStatus.popUps.paymentUnidentified.title"),
        html: `
        <div class="d-flex justify-content-center align-items-center text-center flex-column">
          <p class="mb-2">
          ${t("CheckoutStatus.popUps.paymentUnidentified.description")}
          </p>
        </div>
        `,
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (status === 'pending') {
      const callOrderStatus = async () => {
        const {status, expire_date} = await getOrderStatus();
        return {orderStatus: status, expire_date};
      };
  
      const interval = setInterval(async () => {
        const {orderStatus, expire_date} = await callOrderStatus();
  
        if (orderStatus === "finalizado") {
          setLoading(true)
          clearSessionAndCloseTo("concluded");
        }
        if (orderStatus === "expirado" || new Date(expire_date) < new Date()) {
          setLoading(true)
          clearSessionAndCloseTo("timeout");
        }
      }, 60000);
  
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const checkoutButtons = {
    pix: (
      <Fragment>
        <PixSection
          isCopy={isCopy}
          handleCopy={handleCopyPix}
          handlePayment={handleCheckPayment}
        />
      </Fragment>
    ),
    picpay: (
      <Fragment>
        <PicpaySection code={picpayCode} handleRedirect={handleRedirect} handlePayment={handleCheckPayment}/>
      </Fragment>
    ),
    concluded: (
      <Fragment>
        <ConcludedSection handleRedirect={handleRedirect} />
      </Fragment>
    ),
    processing: (
      <Fragment>
        <ProcessingSection handleRedirect={handleRedirect} status={status} />
      </Fragment>
    ),
    boleto: (
      <Fragment>
        <BoletoSection handleRedirect={handleRedirect} />
      </Fragment>
    ),
  };

  return (
    <Fragment>
      <NewLoading
        showModal={loading}
        title={t("Loading.verifying")}
        subtitle={t("Loading.mayTakeAWhile")}
      />
      <div className="d-flex justify-content-center mt-3">
        <div className="d-flex justify-content-center flex-column mt-3 main-card">
          <Card className={`checkout-card ${status}`}>
            <Card.Body id="checkout-content">
              <div className="d-flex justify-content-center icon-header">
                <Image src={checkoutStatus[status].icon} alt="Clock" />
              </div>

              <div className="d-flex justify-content-center align-items-center flex-column">
                <h4 className={`checkout-title ${status}-title`}>
                  {!type && status === "processing"
                    ? t("CheckoutStatus.processing.title", {
                        firstName: user.first_name,
                        token: token.toUpperCase(),
                      })
                    : t(checkoutStatus[status].title, {
                        firstName: user.first_name,
                        token: token.toUpperCase(),
                      })}
                </h4>

                {status === "pending" && ["pix", "picpay"].includes(type) ? (
                  <OrderValueSection
                    status={status}
                    type={type}
                    value={MaskHelper.numberToLocaleCurrency(
                      Number(orderValue)
                    )}
                    code={pix || picpayCode}
                  />
                ) : (
                  <p className={`checkout-text ${status}-text`}>
                    {status === "pending" ? (
                      <Trans
                        i18nKey={`CheckoutStatus.pending.subtitle.${type}`}
                        components={{ strong: <strong /> }}
                      />
                    ) : status === "concluded" ? (
                      t(
                        isWhitelabel
                          ? `CheckoutStatus.${status}.subtitleWhitelabel`
                          : `CheckoutStatus.${status}.subtitle`
                      )
                    ) : (
                      t(`CheckoutStatus.${status}.subtitle`)
                    )}
                  </p>
                )}

                {["pix", "picpay"].includes(type) &&
                  checkoutButtons[type || status]}

                {["pix", "picpay"].includes(type) && (
                  <p className="checkout-text">
                    <Trans
                      i18nKey={`CheckoutStatus.pending.instructions.${type}`}
                      values={{
                        datetime: DateHelper.addMinutesToCurrentTime(
                          Number(
                            JSON.parse(tickets).expiration_in_minutes || 60
                          ),
                          paymentDate
                        ),
                      }}
                      components={{ strong: <strong /> }}
                    />
                  </p>
                )}

                {!["pix", "picpay"].includes(type) && (
                  <>{checkoutButtons[type || status]}</>
                )}

                {!["pix", "picpay"].includes(type) && status === "pending" && (
                  <>
                    <InstructionsSection
                      errorMessage={errorMessage}
                      status={status}
                      type={type}
                    />

                    <TipSection status={status} type={type} />
                  </>
                )}

                <ErrorSection handleRedirect={handleRedirect} status={status} />
              </div>
            </Card.Body>
          </Card>

          {status === "pending" && ["pix", "picpay"].includes(type) && (
            <div className="d-flex justify-content-center flex-column">
              {type === "pix" && <PixAccordion />}

              <Card className={`checkout-card`}>
                <Card.Body id="checkout-card">
                  <div className="d-flex justify-content-center flex-column align-items-center pix-instructions text-center">
                    <h4 className="mb-4">
                      {t("CheckoutStatus.common.qrCode")}
                    </h4>
                    <QRCodeSection
                      code={pix || picpayCode}
                      isPicpay={!!picpayCode}
                    />
                  </div>
                </Card.Body>
              </Card>
            </div>
          )}
        </div>
      </div>
      {!isWhitelabel && (
        <div className="m-3">
          <DownloadApp isOpen={isOpen} handleOpenModal={handleOpenModal} />
        </div>
      )}
    </Fragment>
  );
}
